import {insurance} from '../../nuxt.config.js'

export const state = () => {
	return {
		token: null,
		countries: null
	}
}

export const actions = {
	async getToken({commit}, {email, password} = {}) {
		const form = {
			email: email || insurance.EMAIL,
			password: password || insurance.PASSWORD,
		}
		try {
			const week = 604800
			const token = (await this.$insuranceApi.post('/auth/login', form, {
				transformRequest: (data, headers) => {
					if (process.server) {
						headers['referer'] = ''
						headers['origin'] = ''
					}
					delete headers['Authorization']
					return JSON.stringify(data)
				}
			})).data.data?.token
			commit('setToken', token)
			this.$cookies.set('insuranceToken', token, {path: '/', maxAge: week, sameSite: 'lax'})
			this.$insuranceApi.defaults.headers = {...this.$insuranceApi.defaults.headers, Authorization: `Bearer ${token}`}
			return token
		} catch (e) {
			commit('setError', e, {root: true})
			this.$sentry?.captureException(e)
			throw e
		}
	},
	async getCountries({state, commit, dispatch}) {
		try {
			const lang = this.$i18n.locale

			if (process.client && localStorage) {
				const countries = JSON.parse(localStorage.getItem('insuranceCountries') || null)
				if (countries && countries[lang] && countries[lang].length) {
					commit('setCountries', countries[lang])
					return countries[lang]
				}
			}

			if (!state.token) {
				await dispatch('getToken')
			}

			const countries = (await this.$insuranceApi.get(`search/insurance/countries?lang=${lang}`)).data.data
			commit('setCountries', countries)

			if (process.client && localStorage) {
				localStorage.setItem('insuranceCountries', JSON.stringify({[lang]: countries}))
			}

			return countries
		} catch (e) {
			//commit('setError', e, {root: true})
			this.$sentry?.captureException(e)
			throw e
		}
	},
	async getAllPrograms({state, commit, dispatch}, directions) {
		const promises = []

		try {
			if (!state.countries) {
				await dispatch('getCountries')
			}

			const countries = [...new Set(
				directions
					.map(direction => [direction.departure.country, direction.arrival.country])
					.flat()
					.filter(country => country !== 'UZ' && state.countries.findIndex(item => item.code === country) !== -1)
			)]

			if (!state.token) {
				await dispatch('getToken')
			}

			promises.push(
				this.$insuranceApi.get('/providers/programs?provider=apex&types[]=5&types[]=6&all_airlines=1')
					.then(response => {
						const apexPrograms = response.data.data.list
						apexPrograms
							.sort((a, b) => a.type_id - b.type_id)
							.forEach(program => {
								program.provider = {id: 'apex', name: 'Apex Insurance'}
								program.total = null
							})
						return apexPrograms
					})
					.catch(() => {
						return null
					})
			)

			/*if (countries.length && directions[0].arrival.country !== 'UZ') {
				promises.push(
					this.$insuranceApi.get(`/providers/programs?provider=gross&has_covid=0&countries[]=${countries.join('&countries[]=')}`)
						.then(response => {
							const grossPrograms = response.data.data.list
							grossPrograms.forEach(program => {
								program.provider = {id: 'gross', name: 'Gross Insurance'}
								program.total = null
							})
							const grand1 = grossPrograms.find(program => program.name === 'GRAND 1')
							const grand2 = grossPrograms.find(program => program.name === 'GRAND 2')
							const antivirus1 = grossPrograms.find(program => program.name === 'ANTIVIRUS 1')
							const prestige = grossPrograms.find(program => program.name === 'PRESTIGE')
							if (grand2) {
								return [grand2]
							}
							if (grand1) {
								return [grand1]
							}
							if (antivirus1) {
								return [antivirus1]
							}
							if (prestige) {
								return [prestige]
							}
							if (grossPrograms.length > 0) {
								return [grossPrograms[0]]
							} else {
								return null
							}
						})
						.catch(() => {
							return null
						})
				)
			}*/

			return (await Promise.all(promises)).flat().filter(program => program !== null)
		} catch (e) {
			//commit('setError', e, {root: true})
			this.$sentry?.captureException(e)
			throw e
		}
	},
	async getApexPrograms({state, commit, dispatch}) {
		try {
			if (!state.token) {
				await dispatch('getToken')
			}
			const response = await this.$insuranceApi.get('/providers/programs?provider=apex&types[]=5&types[]=6&all_airlines=1')
			const apexPrograms = response.data.data.list
			apexPrograms
				.sort((a, b) => a.type_id - b.type_id)
				.forEach(program => {
					program.provider = {id: 'apex', name: 'Apex Insurance'}
					program.total = null
				})
			return apexPrograms
		} catch (e) {
			commit('setError', e, {root: true})
			this.$sentry?.captureException(e)
			throw e
		}
	},
	async getGrossPrograms({state, commit, dispatch}, {hasCovid, countries}) {
		try {
			if (!state.token) {
				await dispatch('getToken')
			}
			const response = await this.$insuranceApi.get(`/providers/programs?provider=gross&has_covid=${hasCovid ? 1 : 0}` + countries.map(country => `&countries[]=${country}`).join(''))
			const grossPrograms = response.data.data.list
			grossPrograms.forEach(program => {
				program.provider = {id: 'gross', name: 'Gross Insurance'}
				program.total = null
			})
			return grossPrograms
		} catch (e) {
			commit('setError', e, {root: true})
			this.$sentry?.captureException(e)
			throw e
		}
	},
	async getAllCalculations({state, commit, dispatch}, data) {
		try {
			if (!state.token) {
				await dispatch('getToken')
			}

			const promises = []
			const grossProgram = data.insurance.filter(program => program.provider === 'gross')
			const apexPrograms = data.insurance.filter(program => program.provider === 'apex')

			if (grossProgram.length > 0) {
				const arrivalDate = data.directions[0].arrival.date_time
				const departureDate = data.directions[data.directions.length - 1].departure.date_time
				const beginDate = `${arrivalDate.slice(8, 10)}.${arrivalDate.slice(5, 7)}.${arrivalDate.slice(0, 4)}`
				const endDate = data.grossEndDate || `${departureDate.slice(8, 10)}.${departureDate.slice(5, 7)}.${departureDate.slice(0, 4)}`
				const birthdays = data.passengers.map(passenger => `${passenger.birthdate.slice(8, 10)}.${passenger.birthdate.slice(5, 7)}.${passenger.birthdate.slice(0, 4)}`)
				const query = [
					'provider=gross',
					`lang=${this.$i18n.locale}`,
					'type_id=1',
					'has_covid=0',
					'is_family=0',
					`program_id=${grossProgram[0].program}`,
					`begin_date=${beginDate}`,
					`end_date=${endDate}`,
					`countries[]=${data.directions[0].arrival.country}`,
					`birthdays[]=${birthdays.join('&birthdays[]=')}`
				]

				promises.push(
					this.$insuranceApi.get('/providers/calculations?' + query.join('&'))
						.then(response => {
							return {
								...response.data.data,
								provider: {
									id: 'gross',
									name: response.data.data.provider
								},
								id: response.data.data.program_id
							}
						})
				)
			}

			if (apexPrograms.length > 0) {
				let roundTrip = 0

				if (data.directions.length === 2 &&
					data.directions[0].departure.city === data.directions[1].arrival.city &&
					data.directions[0].arrival.city === data.directions[1].departure.city
				) {
					roundTrip = 1
				}

				apexPrograms.forEach(program => {
					let flightsAmount = data.directions.length

					if (program.program === '1') {
						flightsAmount = data.directions.reduce((flights, direction) => flights + direction.segments.length, 0)
					}

					const query = [
						'provider=apex',
						`program_id=${program.program}`,
						`passengers_amount=${data.passengersAmount}`,
						`flights_amount=${flightsAmount}`,
						`roundtrip=${roundTrip}`
					]

					promises.push(
						this.$insuranceApi.get('/providers/calculations?' + query.join('&'))
							.then(response => {
								return {
									...response.data.data,
									provider: {
										id: 'apex',
										name: response.data.data.provider
									}
								}
							})
					)
				})
			}

			return await Promise.all(promises)
		} catch (e) {
			commit('setError', e, {root: true})
			this.$sentry?.captureException(e)
			throw e
		}
	},
	async getApexCalculations({state, commit, dispatch}, {programs, passengersAmount, flightsAmount, roundTrip}) {
		try {
			if (!state.token) {
				await dispatch('getToken')
			}

			const promises = []

			programs.forEach(program => {
				const query = [
					'provider=apex',
					`program_id=${program.program}`,
					`passengers_amount=${passengersAmount}`,
					`flights_amount=${flightsAmount}`,
					`roundtrip=${roundTrip}`
				]

				promises.push(
					this.$insuranceApi.get('/providers/calculations?' + query.join('&'))
						.then(response => {
							return {
								...response.data.data,
								provider: {
									id: 'apex',
									name: response.data.data.provider
								}
							}
						})
				)
			})

			return await Promise.all(promises)
		} catch (e) {
			commit('setError', e, {root: true})
			this.$sentry?.captureException(e)
			throw e
		}
	},
	async getGrossCalculations({state, commit, dispatch}, {typeId, hasCovid, isFamily, programId, beginDate, endDate, countries, birthdays}) {
		try {
			if (!state.token) {
				await dispatch('getToken')
			}

			const query = [
				'provider=gross',
				`lang=${this.$i18n.locale}`,
				`type_id=${typeId}`,
				`has_covid=${hasCovid}`,
				`is_family=${isFamily}`,
				`program_id=${programId}`,
				`begin_date=${beginDate}`,
				`end_date=${endDate}`,
				`countries[]=${countries.join('&countries[]=')}`,
				`birthdays[]=${birthdays.join('&birthdays[]=')}`
			]

			const response = await this.$insuranceApi.get('/providers/calculations?' + query.join('&'))

			return {
				...response.data.data,
				provider: {
					id: 'gross',
					name: response.data.data.provider
				},
				id: response.data.data.program_id
			}
		} catch (e) {
			//commit('setError', e, {root: true})
			this.$sentry?.captureException(e)
			throw e
		}
	},
	async createOrder({commit}, form) {
		try {
			const response = await this.$api.post('/insurance/orders', form)
			return response.data.data
		} catch (e) {
			commit('setError', e, {root: true})
			this.$sentry?.captureException(e)
			throw e
		}
	}
}

export const mutations = {
	setToken(state, payload) {
		state.token = payload
	},
	setCountries(state, payload) {
		state.countries = payload
	},
}

export const getters = {
	countries(state) {
		return state.countries
	},
}
