import { generateOfferFeatures } from '~/scripts/helpers'

export const state = () => {
	return {

	}
}

export const actions = {
	async getUserOrders({commit}, page = 0) {
		try {
			const response = (await this.$api.get(`/orders?page=${page}&per_page=10`)).data
			const orders = response.data

			orders.forEach(order => {
				order.created = order.created.replace(/-/g, '/')
				order.expire = order.expire ? order.expire.replace(/-/g, '/') : order.expire

				if (order.avia) {
					order.avia.directions.forEach(direction => {
						direction.segments.forEach(segment => {
							segment.arrival.date_time = segment.arrival.date_time.replace(/-/g, '/')
							segment.departure.date_time = segment.departure.date_time.replace(/-/g, '/')
						})
						direction.arrival.date_time = direction.arrival.date_time.replace(/-/g, '/')
						direction.departure.date_time = direction.departure.date_time.replace(/-/g, '/')
					})
				}
			})

			return {
				data: orders,
				pagination: response.pagination
			}
		} catch (e) {
			if (e.response?.status === 404) {
				return null
			}
			commit('setError', e, {root: true})
			this.$sentry?.captureException(e)
			throw e
		}
	},
	async getOrderDetails({commit, rootGetters}, id) {
		try {
			const order = (await this.$api.get('/orders/' + id)).data.data
			const now = new Date(Math.max(Date.now(), rootGetters.serverDate))
			const utc = new Date(now.getTime() + now.getTimezoneOffset() * 60000)

			order.created = order.created.replace(/-/g, '/')
			order.expire = order.expire ? order.expire.replace(/-/g, '/') : order.expire
			order.deadline = order.expire ? Date.now() + (new Date(order.expire) - utc) : null

			if (order.avia) {
				const airlineCodes = []
				const change = []
				const refund = []
				order.avia.directions.forEach(direction => {
					direction.segments.forEach(segment => {
						segment.arrival.date_time = segment.arrival.date_time.replace(/-/g, '/')
						segment.departure.date_time = segment.departure.date_time.replace(/-/g, '/')
						airlineCodes.push(segment.airline.code)
						change.push(segment.change)
						refund.push(segment.refund)
					})
					direction.arrival.date_time = direction.arrival.date_time.replace(/-/g, '/')
					direction.departure.date_time = direction.departure.date_time.replace(/-/g, '/')
				})
				order.avia.passengers.forEach(passenger => {
					passenger.birthdate = passenger.birthdate.replace(/-/g, '/')
				})
				order.avia.airlineCodes = [...new Set(airlineCodes)]
				order.change = change.every(value => value)
				order.refund = refund.every(value => value)
				order.avia.features = generateOfferFeatures(order.avia)
			}

			return order
		} catch (e) {
			if ([403, 404].includes(e.response?.status)) {
				return null
			}
			commit('setError', e, {root: true})
			this.$sentry?.captureException(e)
			throw e
		}
	},
	async getOrderPaymentLink({commit}, id) {
		try {
			const response = await this.$api.get(`/orders/${id}/payment-link?payment_locale=${this.$i18n.locale}`)
			return response.data.data
		} catch (e) {
			//commit('setError', e, {root: true})
			this.$sentry?.captureException(e)
			throw e
		}
	},
	async sendCancellationReason({commit}, {id, reason, description}) {
		try {
			const response = await this.$api.post('/orders/cancellation-reason', {
				order_id: id,
				reason_code: reason,
				reason_description: description
			})
			return response.data.data
		} catch (e) {
			//commit('setError', e, {root: true})
			this.$sentry?.captureException(e)
			throw e
		}
	},
	async cancelUnpaidOrder({commit}, id) {
		try {
			const response = await this.$api.delete(`/orders/${id}/cancel-unpaid`)
			return response.data.data
		} catch (e) {
			commit('setError', e, {root: true})
			this.$sentry?.captureException(e)
			throw e
		}
	},
	async voidOrder({commit}, id) {
		try {
			const response = await this.$api.delete(`/orders/${id}/void`)
			return response.data.data
		} catch (e) {
			if (e.response?.status !== 422) {
				//commit('setError', e, {root: true})
				this.$sentry?.captureException(e)
			}
			throw e
		}
	},
	async refundAmount({commit}, id) {
		try {
			const response = await this.$api.get(`/orders/${id}/refund-amount`)
			return response.data.data
		} catch (e) {
			if (e.response?.status !== 422) {
				//commit('setError', e, {root: true})
				this.$sentry?.captureException(e)
			}
			throw e
		}
	},
	async autoCancellationPaidOrder({commit}, id) {
		try {
			const response = await this.$api.delete(`/orders/${id}/auto-cancellation-paid`)
			return response.data.data
		} catch (e) {
			if (e.response?.status !== 422) {
				//commit('setError', e, {root: true})
				this.$sentry?.captureException(e)
			}
			throw e
		}
	},
	async manualCancellationPaidOrder({commit}, id) {
		try {
			const response = await this.$api.delete(`/orders/${id}/manual-cancellation-paid`)
			return response.data.data
		} catch (e) {
			commit('setError', e, {root: true})
			this.$sentry?.captureException(e)
			throw e
		}
	},
	async payFromDeposit({commit}, form) {
		try {
			const response = await this.$api.post('/orders/pay-from-deposit', form)
			return response.data.data
		} catch (e) {
			commit('setError', e, {root: true})
			this.$sentry?.captureException(e)
			throw e
		}
	},
	async checkBalance({commit}, orderId) {
		try {
			const response = await this.$api.get(`/orders/${orderId}/before-payment`)
			return response.data.data
		} catch (e) {
			commit('setError', e, {root: true})
			this.$sentry?.captureException(e)
			throw e
		}
	},
}

export const mutations = {

}

export const getters = {

}
